const icons = [
  "flag-icon-ad",
  "flag-icon-ae",
  "flag-icon-af",
  "flag-icon-ag",
  "flag-icon-ai",
  "flag-icon-al",
  "flag-icon-am",
  "flag-icon-ao",
  "flag-icon-aq",
  "flag-icon-ar",
  "flag-icon-as",
  "flag-icon-at",
  "flag-icon-au",
  "flag-icon-aw",
  "flag-icon-ax",
  "flag-icon-az",
  "flag-icon-ba",
  "flag-icon-bb",
  "flag-icon-bd",
  "flag-icon-be",
  "flag-icon-bf",
  "flag-icon-bg",
  "flag-icon-bh",
  "flag-icon-bi",
  "flag-icon-bj",
  "flag-icon-bl",
  "flag-icon-bm",
  "flag-icon-bn",
  "flag-icon-bo",
  "flag-icon-bq",
  "flag-icon-br",
  "flag-icon-bs",
  "flag-icon-bt",
  "flag-icon-bv",
  "flag-icon-bw",
  "flag-icon-by",
  "flag-icon-bz",
  "flag-icon-ca",
  "flag-icon-cc",
  "flag-icon-cd",
  "flag-icon-cf",
  "flag-icon-cg",
  "flag-icon-ch",
  "flag-icon-ci",
  "flag-icon-ck",
  "flag-icon-cl",
  "flag-icon-cm",
  "flag-icon-cn",
  "flag-icon-co",
  "flag-icon-cr",
  "flag-icon-cu",
  "flag-icon-cv",
  "flag-icon-cw",
  "flag-icon-cx",
  "flag-icon-cy",
  "flag-icon-cz",
  "flag-icon-de",
  "flag-icon-dj",
  "flag-icon-dk",
  "flag-icon-dm",
  "flag-icon-do",
  "flag-icon-dz",
  "flag-icon-ec",
  "flag-icon-ee",
  "flag-icon-eg",
  "flag-icon-eh",
  "flag-icon-er",
  "flag-icon-es",
  "flag-icon-et",
  "flag-icon-fi",
  "flag-icon-fj",
  "flag-icon-fk",
  "flag-icon-fm",
  "flag-icon-fo",
  "flag-icon-fr",
  "flag-icon-ga",
  "flag-icon-gb",
  "flag-icon-gd",
  "flag-icon-ge",
  "flag-icon-gf",
  "flag-icon-gg",
  "flag-icon-gh",
  "flag-icon-gi",
  "flag-icon-gl",
  "flag-icon-gm",
  "flag-icon-gn",
  "flag-icon-gp",
  "flag-icon-gq",
  "flag-icon-gr",
  "flag-icon-gs",
  "flag-icon-gt",
  "flag-icon-gu",
  "flag-icon-gw",
  "flag-icon-gy",
  "flag-icon-hk",
  "flag-icon-hm",
  "flag-icon-hn",
  "flag-icon-hr",
  "flag-icon-ht",
  "flag-icon-hu",
  "flag-icon-id",
  "flag-icon-ie",
  "flag-icon-il",
  "flag-icon-im",
  "flag-icon-in",
  "flag-icon-io",
  "flag-icon-iq",
  "flag-icon-ir",
  "flag-icon-is",
  "flag-icon-it",
  "flag-icon-je",
  "flag-icon-jm",
  "flag-icon-jo",
  "flag-icon-jp",
  "flag-icon-ke",
  "flag-icon-kg",
  "flag-icon-kh",
  "flag-icon-ki",
  "flag-icon-km",
  "flag-icon-kn",
  "flag-icon-kp",
  "flag-icon-kr",
  "flag-icon-kw",
  "flag-icon-ky",
  "flag-icon-kz",
  "flag-icon-la",
  "flag-icon-lb",
  "flag-icon-lc",
  "flag-icon-li",
  "flag-icon-lk",
  "flag-icon-lr",
  "flag-icon-ls",
  "flag-icon-lt",
  "flag-icon-lu",
  "flag-icon-lv",
  "flag-icon-ly",
  "flag-icon-ma",
  "flag-icon-mc",
  "flag-icon-md",
  "flag-icon-me",
  "flag-icon-mf",
  "flag-icon-mg",
  "flag-icon-mh",
  "flag-icon-mk",
  "flag-icon-ml",
  "flag-icon-mm",
  "flag-icon-mn",
  "flag-icon-mo",
  "flag-icon-mp",
  "flag-icon-mq",
  "flag-icon-mr",
  "flag-icon-ms",
  "flag-icon-mt",
  "flag-icon-mu",
  "flag-icon-mv",
  "flag-icon-mw",
  "flag-icon-mx",
  "flag-icon-my",
  "flag-icon-mz",
  "flag-icon-na",
  "flag-icon-nc",
  "flag-icon-ne",
  "flag-icon-nf",
  "flag-icon-ng",
  "flag-icon-ni",
  "flag-icon-nl",
  "flag-icon-no",
  "flag-icon-np",
  "flag-icon-nr",
  "flag-icon-nu",
  "flag-icon-nz",
  "flag-icon-om",
  "flag-icon-pa",
  "flag-icon-pe",
  "flag-icon-pf",
  "flag-icon-pg",
  "flag-icon-ph",
  "flag-icon-pk",
  "flag-icon-pl",
  "flag-icon-pm",
  "flag-icon-pn",
  "flag-icon-pr",
  "flag-icon-ps",
  "flag-icon-pt",
  "flag-icon-pw",
  "flag-icon-py",
  "flag-icon-qa",
  "flag-icon-re",
  "flag-icon-ro",
  "flag-icon-rs",
  "flag-icon-ru",
  "flag-icon-rw",
  "flag-icon-sa",
  "flag-icon-sb",
  "flag-icon-sc",
  "flag-icon-sd",
  "flag-icon-se",
  "flag-icon-sg",
  "flag-icon-sh",
  "flag-icon-si",
  "flag-icon-sj",
  "flag-icon-sk",
  "flag-icon-sl",
  "flag-icon-sm",
  "flag-icon-sn",
  "flag-icon-so",
  "flag-icon-sr",
  "flag-icon-ss",
  "flag-icon-st",
  "flag-icon-sv",
  "flag-icon-sx",
  "flag-icon-sy",
  "flag-icon-sz",
  "flag-icon-tc",
  "flag-icon-td",
  "flag-icon-tf",
  "flag-icon-tg",
  "flag-icon-th",
  "flag-icon-tj",
  "flag-icon-tk",
  "flag-icon-tl",
  "flag-icon-tm",
  "flag-icon-tn",
  "flag-icon-to",
  "flag-icon-tr",
  "flag-icon-tt",
  "flag-icon-tv",
  "flag-icon-tw",
  "flag-icon-tz",
  "flag-icon-ua",
  "flag-icon-ug",
  "flag-icon-um",
  "flag-icon-us",
  "flag-icon-uy",
  "flag-icon-uz",
  "flag-icon-va",
  "flag-icon-vc",
  "flag-icon-ve",
  "flag-icon-vg",
  "flag-icon-vi",
  "flag-icon-vn",
  "flag-icon-vu",
  "flag-icon-wf",
  "flag-icon-ws",
  "flag-icon-ye",
  "flag-icon-yt",
  "flag-icon-za",
  "flag-icon-zm",
  "flag-icon-zw",
  "flag-icon-es-ct",
  "flag-icon-eu",
  "flag-icon-gb-eng",
  "flag-icon-gb-nir",
  "flag-icon-gb-sct",
  "flag-icon-gb-wls",
  "flag-icon-un"
];
export default icons;
